import React, { useEffect, useReducer } from 'react'
import { Header } from './components/common/Header';
import { Footer } from './components/common/Footer';
import Top from './components/Top';
import Characters from './components/Characters';
import CharacterDetail from './components/CharacterDetail';
import NotFound from './components/NotFound';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { CharactersContext, initStateCharacters } from './contexts/FirebaseContext'
import { CharactersReducer } from './reducers/FirestoreReducer'
import { getCharacters } from './firebase/firestore'
import { getImageMap } from './firebase/storage'
import { charactersInitAction, charactersSuccessAction, charactersErrorAction, charactersSortAction } from './actions/FirestoreActionCreator'
import { appStyles } from './assets/style/app'

const App = () => {
  const [charactersState, charactersDispatch] = useReducer(CharactersReducer, initStateCharacters);
  useEffect(() => {
    const fetchCharacters = async () => {
      charactersInitAction(charactersDispatch);
      try {
        const characters = await getCharacters();
        const charactersMainImageNames = characters.map((character: any) => {
          return character.main_img
        })
        const charactersIconImageNames = characters.map((character: any) => {
          return character.icon_img
        })
        const charactersImageMap = await getImageMap('character_img/', charactersMainImageNames.concat(charactersIconImageNames))
        charactersSuccessAction(charactersDispatch, characters, charactersImageMap)
        charactersSortAction(charactersDispatch, 'name_en', false);
      } catch (error) {
        charactersErrorAction(charactersDispatch)
      }
    }
    fetchCharacters()
  }, [])

  const { app } = appStyles();

  return (
    <div className={app}>
      <Router>
        <Header />
        <CharactersContext.Provider value={{ charactersState: charactersState, charactersDispatch: charactersDispatch }}>
          <Switch>
            <Route path='/' component={Top} exact />
            <Route path='/characters' component={Characters} exact />
            <Route path='/characters/:id' component={CharacterDetail} exact />
            <Route component={NotFound} />
          </Switch>
        </CharactersContext.Provider>
        <Footer />
      </Router >
    </div>
  )
}

export default App;
