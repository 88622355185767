import React, { memo } from 'react';
import { footerStyles } from "../../assets/style/footer";
import Fab from '@material-ui/core/Fab';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import { animateScroll as scroll } from 'react-scroll';

const scrollToTop = () => {
    scroll.scrollToTop();
};

export const Footer = memo(() => {
    const { footer, footerCopyright, upBtn } = footerStyles();

    return (
        <footer className={footer} >
            {/* <Fab aria-label="Expand" className={upBtn} onClick={scrollToTop}>
            <UpIcon />
        </Fab> */}
            <div className={footerCopyright}>2021 © CUCURUCu Inc.</div>
        </footer >)
});