import { memo } from 'react'
import { commonStyles } from '../../assets/style/common';
import { errorStyles } from "../../assets/style/error";

const Error = memo((props: any) => {
    const { componentArea, heading1 } = commonStyles();
    const { errorMsgArea } = errorStyles();

    return (
        <div className={componentArea}>
            <h1 className={heading1}>Error</h1>
            <div className={errorMsgArea}>
                {props.errorMsg}
            </div>
        </div>
    )
});

export default Error;
