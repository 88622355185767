import {
    makeStyles,
} from "@material-ui/core";

export const errorStyles = makeStyles(() => ({
    errorMsgArea: {
        textAlign: "center",
        paddingTop: "80px",
        paddingBottom: "200px",
        // color: '#ACB6E5 !important',
    },
}));