import {
    makeStyles,
} from "@material-ui/core";

export const characterDetailStyles = makeStyles(() => ({
    characterDetailArea: {
        marginTop: '50px',
        marginLeft: '5%',
        marginRight: '5%',
        '@media (max-width:600px)': {
            display: "30px",
        },
    },
    nameAreaPc: {
        width: '100%',
        textAlign: 'center',
        '@media (max-width:600px)': {
            display: "none",
        },
    },
    nameAreaSp: {
        width: '100%',
        textAlign: 'center',
        '@media (min-width:600px)': {
            display: "none",
        },
    },
    nameJaArea: {
        fontSize: '40px',
        margin: 'initial !important',
    },
    nameEnArea: {
        fontSize: '20px',
        margin: 'initial !important',
        color: '#6d6d6d',
    },
    characterImg: {
        width: '100%',
        // hight: '100%',
        objectFit: 'contain',
        borderRadius: '20px',
        // boxShadow: 'rgb(66 66 66 / 15%) 0px 10px 20px !important',
    },
    characterAreaWrap: {
        marginTop: '20px',
        padding: '20px',
        borderRadius: '20px',
        boxShadow: 'rgb(66 66 66 / 15%) 0px 10px 20px !important',
        '@media (max-width:600px)': {
            padding: '10px',
        },
    },
    characterDescription: {
        paddingTop: '20px',
    },
    characterYouTube: {
        paddingTop: '20px',
        '@media (max-width:600px)': {
            paddingTop: 'initial',
        },
    },
    characterSnsLink: {
        textAlign: 'right',
        paddingTop: '20px',
    },
    characterYouTubeLink: {
        color: '#ff0000',
        padding: '5px'
    },
    characterTwitterLink: {
        color: '#5ba4d5',
        padding: '5px'
    },
    charactersButton: {
        float: 'right',
        marginTop: '30px',
        paddingLeft: '15px',
        paddingRight: '15px',
    },
}));