import { useEffect, useContext, useState, useRef, memo } from 'react';
import { titleName, titleSeparator } from '../assets/const';
import { charactersStyles } from '../assets/style/characters';
import { commonStyles } from '../assets/style/common';
import { Button } from "@material-ui/core";
import FormatLineSpacing from "@material-ui/icons/FormatLineSpacing";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Grid from '@material-ui/core/Grid';
import { CharactersContext } from '../contexts/FirebaseContext';
import { charactersSortAction } from '../actions/FirestoreActionCreator';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Loading from './common/Loading';
import Error from './common/Error';

const Characters = memo(() => {
    useEffect(() => { document.title = `キャラクター ${titleSeparator}${titleName}` }, []);

    const { charactersState, charactersDispatch } = useContext(CharactersContext);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const sortHandleToggle = () => {
        setOpen((sortOpen) => !sortOpen);
    };
    const sortHandleClose = () => {
        setOpen(false);
    };
    const orderHandleToggle = () => {
        setOpen((prevOrder) => !prevOrder);
    };
    const [sortBy, setSort] = useState({ nameEn: 'name_en', nameJa: '名前順' });
    const [isDesc, setOrder] = useState(false);
    const charactersSortActionButton = (dispatch: any, sortBy: any, isDesc: boolean) => {
        charactersSortAction(dispatch, sortBy.nameEn, isDesc)
        setSort(sortBy);
        setOrder(isDesc);
        sortHandleClose();
    };

    const {
        sortButtonArea,
        sortByPopperArea,
        buttomRightSpace,
        buttonListText,
        gridContainer,
        gridItem,
        characterImg,
        characterName,
    } = charactersStyles();

    const {
        componentArea,
        heading1,
        button,
        buttonText,
    } = commonStyles();

    // loading
    if (charactersState.isLoading) {
        return <Loading />;
    }
    // success
    else if (charactersState.errorMsg === '' && charactersState.store.length > 0) {
        return (
            <div className={componentArea}>
                <h1 className={heading1}>CHARACTERS</h1>
                <div className={sortButtonArea}>
                    <Button
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={sortHandleToggle}
                        className={button + ' ' + buttomRightSpace}
                        variant="contained"
                    >
                        <ExpandMore />
                        <span className={buttonText}>{sortBy.nameJa}</span>
                    </Button>
                    <Popper className={sortByPopperArea} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={sortHandleClose}>
                                        <MenuList autoFocusItem={open} id="menu-list-grow">
                                            <MenuItem onClick={() => charactersSortActionButton(charactersDispatch, { nameEn: 'name_en', nameJa: '名前順' }, isDesc)}>
                                                <span className={buttonListText}>名前順</span>
                                            </MenuItem>
                                            <MenuItem onClick={() => charactersSortActionButton(charactersDispatch, { nameEn: 'youtube_subscriber_count', nameJa: '登録者数順' }, isDesc)}>
                                                <span className={buttonListText}>登録者数順</span>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                    <Button className={button} onClick={() => charactersSortActionButton(charactersDispatch, { nameEn: sortBy.nameEn, nameJa: sortBy.nameJa }, !isDesc)} variant="contained">
                        <FormatLineSpacing />
                        <span className={buttonText}>{isDesc ? '降順' : '昇順'}</span>
                    </Button>
                </div>
                <Grid container className={gridContainer}>
                    {
                        charactersState.store.map((character: any) => {
                            return (
                                <Grid item className={gridItem} key={character.name_en} xs={6} sm={4}>
                                    <a href={'/characters/' + character.name_en}>
                                        <img className={characterImg} src={charactersState.storage.get(character.icon_img)} alt={character.name_en} />
                                    </a>
                                    <div className={characterName}>{character.name_ja}</div>
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </div >
        )
    }
    // error
    else if (charactersState.errorMsg !== '') {
        return <Error errorMsg={charactersState.errorMsg} />
    }
    // 下記のコンポーネントが表示されることはないが念のため設置
    return (
        <div className={componentArea}>
            <h1 className={heading1}>CHARACTERS</h1>
        </div>
    )
});

export default Characters;
