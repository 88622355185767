import {
    makeStyles,
} from "@material-ui/core";

export const topStyles = makeStyles(() => ({
    aboutArea: {
        marginTop: '50px',
        marginLeft: '20%',
        marginRight: '20%',
        '@media (max-width:1024px)': {
            marginLeft: '10%',
            marginRight: '10%',
        },
    },
    aboutAreaWithImg: {
        marginTop: '50px',
        marginLeft: '15%',
        marginRight: '15%',
        '@media (max-width:1024px)': {
            marginLeft: '10%',
            marginRight: '10%',
        },
    },
    aboutImg: {
        width: '100%',
    },
    aboutTitle: {
        fontSize: "2.3rem",
        color: "#ACB6E5",
        textAlign: "center",
        opacity: '0.5',
        fontWeight: 'bold',
        '@media (max-width:600px)': {
            fontSize: "2rem",
        },
    },
    aboutDetail: {
        fontSize: "1.2rem",
        textAlign: "center",
        paddingTop: '20px',
        paddingBottom: '20px',
        '@media (max-width:600px)': {
            fontSize: "1rem",
        },
    },
    charactersButton: {
        float: 'right',
        paddingLeft: '15px',
        paddingRight: '15px',
    },
    carouselArea: {
        textAlign: "center",
    },
    carouselImgArea: {
        marginLeft: '10%',
        marginRight: '10%',
        '@media (max-width:1024px)': {
            marginLeft: '3%',
            marginRight: '3%',
        },
        '@media (max-width:600px)': {
            marginTop: '20px',
        }
    },
    carouselImg: {
        width: '100%',
        height: 'auto',
        // borderRadius: '10px',
    },
}));