import { useContext, useEffect, memo } from 'react';
import { CharactersContext } from '../contexts/FirebaseContext';
import { commonStyles } from '../assets/style/common';
import { characterDetailStyles } from '../assets/style/characterDetail';
import { titleName, titleSeparator } from '../assets/const';
import Loading from './common/Loading';
import Error from './common/Error';
import YouTubeIfreme from './common/YouTubeIfreme';
import NotFound from './NotFound';
import Grid from '@material-ui/core/Grid';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { Button } from "@material-ui/core";

const CharacterDetail = memo((props: any) => {
    const { id } = props.match.params;
    const { charactersState, charactersDispatch } = useContext(CharactersContext);
    useEffect(() => { document.title = `${id} ${titleSeparator}${titleName}` }, []);

    const {
        heading1,
        componentArea,
        button,
        buttonText,
    } = commonStyles();
    const {
        characterDetailArea,
        nameAreaPc,
        nameAreaSp,
        nameJaArea,
        nameEnArea,
        characterImg,
        characterAreaWrap,
        characterDescription,
        characterYouTube,
        characterSnsLink,
        characterYouTubeLink,
        characterTwitterLink,
        charactersButton,
    } = characterDetailStyles();
    // loading
    if (charactersState.isLoading) {
        return <Loading />;
    }
    // success
    else if (charactersState.errorMsg === '' && charactersState.store.length > 0) {
        let targetCharacter = charactersState.store.find((character) => character.name_en === String(id));

        if (targetCharacter != null) {
            return (
                <div className={componentArea}>
                    <div className={characterDetailArea}>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <div className={nameAreaSp}>
                                    <h2 className={nameJaArea}>{targetCharacter.name_ja}</h2>
                                    <h5 className={nameEnArea}>{targetCharacter.name_en}</h5>
                                </div>
                                <div>
                                    <img className={characterImg} src={charactersState.storage.get(targetCharacter.main_img)} alt={targetCharacter.name_en} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={characterAreaWrap}>
                                    <div className={nameAreaPc}>
                                        <h2 className={nameJaArea}>{targetCharacter.name_ja}</h2>
                                        <h5 className={nameEnArea}>{targetCharacter.name_en}</h5>
                                    </div>
                                    <div className={characterYouTube}>
                                        <YouTubeIfreme videoId={targetCharacter.youtube_introduction_video_id} />
                                    </div>
                                    <div className={characterDescription}>
                                        <span dangerouslySetInnerHTML={{ __html: targetCharacter.description }}></span>
                                    </div>
                                    <div className={characterSnsLink}>
                                        <a target="_blank" className={characterYouTubeLink} href={'https://www.youtube.com/channel/' + targetCharacter.youtube_id} title={targetCharacter.name_ja + 'のYouTube'} >
                                            <YouTubeIcon fontSize='large' />
                                        </a>
                                        <a target="_blank" className={characterTwitterLink} href={'https://twitter.com/' + targetCharacter.twitter_id} title={targetCharacter.name_ja + 'のTwitter'} >
                                            <TwitterIcon fontSize='large' />
                                        </a>
                                    </div>
                                </div>
                                <Button className={button + ' ' + charactersButton} href="/characters">
                                    <span className={buttonText}>キャラクター一覧</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div >
            )
        } else {
            return <NotFound />
        }
    }
    // error
    else if (charactersState.errorMsg !== '') {
        return <Error errorMsg={charactersState.errorMsg} />
    }
    // 下記のコンポーネントが表示されることはないが念のため設置
    return (
        <div className={componentArea}>
            <h1 className={heading1}>CHARACTERS DETAIL</h1>
        </div>
    )
});

export default CharacterDetail;
