import {
    makeStyles,
} from "@material-ui/core";

export const youtubeIframeStyles = makeStyles(() => ({
    youtubeArea: {
        position: 'relative',
        paddingBottom: '56.25%',
        height: '0',
        overflow: 'hidden',
    },
    youtubeIframeWrap: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
    },
}));