export enum FirestoreActionType {
    // キャラクター操作系アクション
    charactersInitType = 'CHARACTERS_FETCH_INIT',
    charactersSuccessType = 'CHARACTERS_FETCH_SUCCESS',
    charactersErrorType = 'CHARACTERS_FETCH_ERROR',
    charactersSortType = 'CHARACTERS_SORT',
    // トップスライダー操作型アクション
    topCarouselsInitType = 'TOP_CAROUSELS_FETCH_INIT',
    topCarouselsSuccessType = 'TOP_CAROUSELS_FETCH_SUCCESS',
    topCarouselsErrorType = 'TOP_CAROUSELS_FETCH_ERROR',
};

// キャラクター操作系アクション
export const charactersInitAction = (dispatch: any) => {
    const action = {
        type: FirestoreActionType.charactersInitType,
    };
    dispatch(action);
};
export const charactersSuccessAction = (dispatch: any, store: any, storage: any) => {
    const action = {
        type: FirestoreActionType.charactersSuccessType,
        fetchStore: store,
        fetchStorage: storage
    };
    dispatch(action);
};
export const charactersErrorAction = (dispatch: any) => {
    const action = {
        type: FirestoreActionType.charactersErrorType,
    };
    dispatch(action);
};
export const charactersSortAction = (dispatch: any, sort: string, isDesc: boolean) => {
    const action = {
        type: FirestoreActionType.charactersSortType,
        query: {
            sortedBy: sort,
            isDesc: isDesc
        }
    };
    dispatch(action);
};

// トップスライダー操作型アクション
export const topCarouselsInitAction = (dispatch: any) => {
    const action = {
        type: FirestoreActionType.topCarouselsInitType,
    };
    dispatch(action);
};
export const topCarouselsErrorAction = (dispatch: any) => {
    const action = {
        type: FirestoreActionType.topCarouselsErrorType,
    };
    dispatch(action);
};
export const topCarouselsSuccessAction = (dispatch: any, store: any, storage: any) => {
    const action = {
        type: FirestoreActionType.topCarouselsSuccessType,
        fetchStore: store,
        fetchStorage: storage
    };
    dispatch(action);
};