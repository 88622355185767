import firebase from '../firebase/firebaseInit'
import 'firebase/firestore'

export const getCharacters = async () => {
    const firestore = firebase.firestore()
    const charactersRef = firestore.collection('characters').orderBy('name_en', 'desc')
    const charactersSnapshots = await charactersRef.get()
    let datas = await charactersSnapshots.docs.map(doc => doc.data())
    return datas
}

export const getTopCarousels = async () => {
    const firestore = firebase.firestore()
    const topCarouselsRef = firestore.collection('top_carousels').orderBy('sort_number')
    const topCarouselsSnapshots = await topCarouselsRef.get()
    let datas = await topCarouselsSnapshots.docs.map(doc => doc.data())
    return datas
}