import {
    makeStyles,
} from "@material-ui/core";

export const charactersStyles = makeStyles(() => ({
    sortButtonArea: {
        textAlign: "center",
    },
    sortByPopperArea: {
        top: '15px !important',
    },
    buttomRightSpace: {
        marginRight: '20px',
    },
    buttonListText: {
        fontSize: '17px',
        fontWeight: "bold",
        '@media (max-width:600px)': {
            fontSize: '13px',
        },
    },
    gridContainer: {
        paddingTop: "80px",
        // paddingBottom: "180px",
        paddingLeft: "50px",
        paddingRight: "50px",
        textAlign: 'center',
        '@media (max-width:600px)': {
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "50px",
            // paddingBottom: "100px",
        },
    },
    gridItem: {
        paddingBottom: "80px",
        '@media (max-width:600px)': {
            paddingBottom: "40px",
        },
    },
    characterImg: {
        width: '70%',
        // hight: '100%',
        objectFit: 'contain',
        borderRadius: '20px',
        boxShadow: 'rgb(66 66 66 / 15%) 0px 10px 20px !important',
    },
    characterName: {
        fontSize: '15px',
        fontWeight: "bold",
        paddingTop: '10px',
        '@media (max-width:600px)': {
            fontSize: '12px',
            paddingTop: '5px',
        },
    }
}));