import { createContext } from 'react'

export const initStateCharacters = {
    isLoading: false,
    errorMsg: '',
    store: [],
    storage: new Map<string, string>(),
};

export const initStateTopCarousels = {
    isLoading: false,
    errorMsg: '',
    store: [],
    storage: new Map<string, string>(),
};

export interface Character {
    document_id: string;
    main_img: string;
    name_en: string;
    icon_img: string;
    name_ja: string;
    description: string;
    youtube_id: string;
    twitter_id: string;
    youtube_introduction_video_id: string;
    youtube_subscriber_count: number;
    is_display_top: boolean;
}

export interface TopCarousel {
    img: string;
    link: string;
    sort_number: number;
}

interface CharactersContextType {
    charactersState: {
        isLoading: boolean
        errorMsg: string;
        store: Character[];
        storage: Map<string, string>;
    };
    charactersDispatch: React.Dispatch<any> | null;
};

interface TopCarouselsContextType {
    topCarouselsState: {
        isLoading: boolean
        errorMsg: string;
        store: TopCarousel[];
        storage: Map<string, string>;
    };
    topCarouselsDispatch: React.Dispatch<any> | null;
};

export const CharactersContext = createContext<CharactersContextType>({
    charactersState: initStateCharacters,
    charactersDispatch: null
});

export const TopCarouselsContext = createContext<TopCarouselsContextType>({
    topCarouselsState: initStateTopCarousels,
    topCarouselsDispatch: null
});