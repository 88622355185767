import { useEffect, useContext, memo, useReducer } from 'react';
import { titleName } from '../assets/const';
import { topStyles } from '../assets/style/top';
import { commonStyles } from '../assets/style/common';
import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { CharactersContext, initStateTopCarousels } from '../contexts/FirebaseContext';
import { getTopCarousels } from '../firebase/firestore'
import { TopCarouselsReducer } from '../reducers/FirestoreReducer'
import { topCarouselsInitAction, topCarouselsSuccessAction, topCarouselsErrorAction } from '../actions/FirestoreActionCreator'
import { getImageMap } from '../firebase/storage'
import Loading from './common/Loading';
import Error from './common/Error';
import Carousel from 'react-material-ui-carousel';

const Top = memo(() => {
    useEffect(() => { document.title = titleName }, []);

    const [topCarouselsState, topCarouselsDispatch] = useReducer(TopCarouselsReducer, initStateTopCarousels);
    useEffect(() => {
        const fetchTopCarousels = async () => {
            topCarouselsInitAction(topCarouselsDispatch);
            try {
                const topCarousels = await getTopCarousels();
                const topCarouselsImageNames = topCarousels.map((carousels: any) => { return carousels.img })
                const topCarouselsImageMap = await getImageMap('top_carousel_img/', topCarouselsImageNames)
                topCarouselsSuccessAction(topCarouselsDispatch, topCarousels, topCarouselsImageMap)
            } catch (error) {
                topCarouselsErrorAction(topCarouselsDispatch)
            }
        }
        fetchTopCarousels();
    }, [])

    const { charactersState, charactersDispatch } = useContext(CharactersContext);
    const { aboutArea, aboutAreaWithImg, aboutImg, aboutTitle, aboutDetail, charactersButton, carouselArea, carouselImgArea, carouselImg } = topStyles();
    const { componentArea, heading1, button, buttonText } = commonStyles();

    // loading
    if (charactersState.isLoading || topCarouselsState.isLoading) {
        return <Loading />;
    }
    // success
    else if (charactersState.errorMsg === '' && topCarouselsState.errorMsg === '') {
        let carousel;
        if (topCarouselsState.store.length > 0) {
            const CarouselItem = (props: any) => {
                return (
                    <div className={carouselImgArea}>
                        <a href={props.carousel.link}>
                            <img width="474" height="265" className={carouselImg} src={props.img} />
                        </a>
                    </div>
                )
            }
            carousel = (
                <Carousel
                    className={carouselArea}
                >
                    {
                        topCarouselsState.store.map((carousel: any, i: number) => {
                            return <CarouselItem key={i} carousel={carousel} img={topCarouselsState.storage.get(carousel.img)} />
                        })
                    }
                </Carousel >
            )
        }

        const aboutTitleAndDetail = (
            <div>
                <div className={aboutTitle}>V Crewについて</div>
                <div className={aboutDetail}>
                    テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。
                    テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。テスト用のテキストを挿入しています。
                </div>
                <Button className={button + ' ' + charactersButton} href="/characters">
                    <span className={buttonText}>キャラクター一覧</span>
                </Button>
            </div>
        );
        let targetCharacter = charactersState.store.find((character) => character.is_display_top === true);
        let about;
        if (targetCharacter != null) {
            about = (
                <div className={aboutAreaWithImg}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            {aboutTitleAndDetail}
                        </Grid>
                        <Hidden only="xs">
                            <Grid item xs={12} sm={6}>
                                < a href={'/characters/' + targetCharacter.name_en} >
                                    <img className={aboutImg} src={charactersState.storage.get(targetCharacter.main_img)} alt={targetCharacter.name_en} />
                                </a>
                            </Grid>
                        </Hidden>
                    </Grid >
                </div>
            )
        } else {
            about = (
                <div className={aboutArea}>
                    {aboutTitleAndDetail}
                </div>
            )
        }
        return (
            <div className={componentArea}>
                {carousel}
                {about}
            </div >
        )
    }
    // characters error
    else if (charactersState.errorMsg !== '') {
        return <Error errorMsg={charactersState.errorMsg} />
    }
    // carousels error
    else if (topCarouselsState.errorMsg !== '') {
        return <Error errorMsg={topCarouselsState.errorMsg} />
    }
    // 下記のコンポーネントが表示されることはないが念のため設置
    return (
        <div className={componentArea}>
            <h1 className={heading1}>TOP</h1>
        </div>
    )
});

export default Top;