import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Drawer,
    Link,
    MenuItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { headerStyles } from "../../assets/style/header";
import { useState, useEffect, memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { contactUrl, companyUrl } from '../../assets/const';

const headersLinkData = [
    {
        label: "CHARACTERS",
        href: "/characters",
        isTargetBlank: false,
    },
    {
        label: "CONTACT",
        href: contactUrl,
        isTargetBlank: true,
    },
    {
        label: "COMPANY",
        href: companyUrl,
        isTargetBlank: true,
    },
];

export const Header = memo(() => {
    const { header, logo, menuButton, toolbar, drawerContainer } = headerStyles();

    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });

    const { mobileView, drawerOpen } = state;

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());
    }, []);

    const displayDesktop = () => {
        return (
            <Toolbar className={toolbar}>
                {femmecubatorLogo}
                <div>{getMenuButtons()}</div>
            </Toolbar>
        );
    };

    const displayMobile = () => {
        const handleDrawerOpen = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: true }));
        const handleDrawerClose = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: false }));

        return (
            <Toolbar>
                <IconButton
                    {...{
                        edge: "start",
                        color: "inherit",
                        "aria-label": "menu",
                        "aria-haspopup": "true",
                        onClick: handleDrawerOpen,
                    }}
                >
                    <MenuIcon />
                </IconButton>

                <Drawer
                    {...{
                        anchor: "left",
                        open: drawerOpen,
                        onClose: handleDrawerClose,
                    }}
                >
                    <div className={drawerContainer}>{getDrawerChoices()}</div>
                </Drawer>

                <div>{femmecubatorLogo}</div>
            </Toolbar >
        );
    };

    const getDrawerChoices = () => {
        const addTopHeadersLinkData = headersLinkData.slice();
        addTopHeadersLinkData.unshift({
            label: "TOP",
            href: "",
            isTargetBlank: false,
        });
        return addTopHeadersLinkData.map(({ label, href, isTargetBlank }) => {
            if (isTargetBlank) {
                return (
                    <a href={href} key={label} target="blank" className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorInherit">
                        <MenuItem>{label}</MenuItem>
                    </a>
                );
            } else {
                return (
                    <Link
                        {...{
                            component: RouterLink,
                            to: href,
                            color: "inherit",
                            style: { textDecoration: "none" },
                            key: label,
                        }}
                    >
                        <MenuItem>{label}</MenuItem>
                    </Link>
                );
            }
        });
    };

    const femmecubatorLogo = (
        <Typography variant="h6" component="h1" className={logo}>
            <Link {...{
                component: RouterLink,
                to: "",
                color: "inherit",
                style: { textDecoration: "none" },
                key: "V Crew",
            }}>V Crew</Link>
        </Typography>
    );

    const getMenuButtons = () => {
        return headersLinkData.map(({ label, href, isTargetBlank }) => {
            if (isTargetBlank) {
                return (
                    <Button
                        {...{
                            key: label,
                            color: "inherit",
                            href: href,
                            target: "blank",
                            className: menuButton,
                        }}
                    >
                        {label}
                    </Button>
                );
            } else {
                return (
                    <Button
                        {...{
                            key: label,
                            color: "inherit",
                            to: href,
                            component: RouterLink,
                            className: menuButton,
                        }}
                    >
                        {label}
                    </Button>
                );
            }
        });
    };

    return (
        <AppBar className={header}>
            {mobileView ? displayMobile() : displayDesktop()}
        </AppBar>
    );
});