import React from 'react';
import { youtubeIframeStyles } from '../../assets/style/youtubeIframe';


const YoutubeIframe = (props: any) => {
    const url = 'https://www.youtube.com/embed/' + props.videoId;

    const { youtubeArea, youtubeIframeWrap } = youtubeIframeStyles();
    return (
        <div className={youtubeArea}>
            <iframe className={youtubeIframeWrap} id="ytplayer" src={url} width="854" height="480" />
        </div>
    )

}

export default YoutubeIframe;