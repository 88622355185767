import {
    makeStyles,
} from "@material-ui/core";

export const loadingStyles = makeStyles(() => ({
    circularProgressArea: {
        textAlign: "center",
        paddingTop: "80px",
        paddingBottom: "200px",
        // color: '#ACB6E5 !important',
    },
    circularProgress: {
        color: '#ACB6E5 !important',
    },
}));