import {
    makeStyles,
} from "@material-ui/core";

export const notFoundStyles = makeStyles(() => ({
    paragraphArea: {
        textAlign: 'center',
        paddingTop: '20px',
        // paddingBottom: '100vh',
    }
})
);