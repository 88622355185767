import { FirestoreActionType } from '../actions/FirestoreActionCreator'

const getInit = () => {
    return {
        isLoading: true,
        errorMsg: '',
        store: [],
        storage: new Map(),
    }
}

const getError = (errorMsg: string) => {
    return {
        isLoading: false,
        errorMsg: errorMsg,
        store: [],
        storage: new Map(),
    }
}

const getData = (stores: any, storage: any) => {
    return {
        isLoading: false,
        errorMsg: '',
        store: stores,
        storage: storage
    }
}

export const CharactersReducer = (state: any, action: any) => {
    switch (action.type) {
        // データの取得初期時
        case FirestoreActionType.charactersInitType:
            return getInit();
        // データの取得に成功した場合
        case FirestoreActionType.charactersSuccessType:
            return getData(action.fetchStore, action.fetchStorage);
        // データの取得に失敗した場合
        case FirestoreActionType.charactersErrorType:
            return getError('エラーが発生しました。');
        // データのソートを行う場合
        case FirestoreActionType.charactersSortType:
            const { sortedBy, isDesc } = action.query;
            let stores = state.store;
            // charactersをname_enの昇順でソート
            stores.sort((a: any, b: any) => {
                if (isDesc) {
                    if (a[sortedBy] < b[sortedBy]) return 1;
                    if (a[sortedBy] > b[sortedBy]) return -1;
                } else {
                    if (a[sortedBy] > b[sortedBy]) return 1;
                    if (a[sortedBy] < b[sortedBy]) return -1;
                }
                return 0;
            });
            return getData(stores, state.storage);
        // defaultではそのまま渡ってきたstateを返しておく
        default:
            return state
    }
}

export const TopCarouselsReducer = (state: any, action: any) => {
    switch (action.type) {
        // データの取得初期時
        case FirestoreActionType.topCarouselsInitType:
            return getInit();
        // データの取得に成功した場合
        case FirestoreActionType.topCarouselsSuccessType:
            return getData(action.fetchStore, action.fetchStorage);
        // データの取得に失敗した場合
        case FirestoreActionType.topCarouselsErrorType:
            return getError('エラーが発生しました。');
        // defaultではそのまま渡ってきたstateを返しておく
        default:
            return state
    }
}
