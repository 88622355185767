import {
    makeStyles,
} from "@material-ui/core";

export const commonStyles = makeStyles(() => ({
    componentArea: {
        paddingTop: "100px",
        paddingBottom: "350px",
        '@media (max-width:600px)': {
            paddingTop: "65px",
            paddingBottom: "250px",
        },
    },
    heading1: {
        fontSize: "6rem",
        color: "#ACB6E5",
        textAlign: "center",
        // paddingTop: "80px",
        // background: 'linear-gradient(to bottom right, blue, pink)',
        // background: '-webkit-linear-gradient(0deg, #ACB6E5, #86FDE8)',
        // WebkitBackgroundClip: "text",
        // WebkitTextFillColor: 'transparent',
        opacity: '0.5',
        '@media (max-width:600px)': {
            fontSize: '2.6rem',
        },
    },
    button: {
        fontSize: "15px !important",
        backgroundColor: "initial",
        borderRadius: 20,
        boxShadow: 'rgb(66 66 66 / 15%) 0px 10px 20px !important',
    },
    buttonText: {
        fontSize: '17px',
        fontWeight: "bold",
        paddingLeft: '5px',
        '@media (max-width:600px)': {
            fontSize: '13px',
        },
    },
}));