import { memo } from 'react';
import { commonStyles } from '../..//assets/style/common';
import { loadingStyles } from "../../assets/style/loading";
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = memo(() => {
    const { circularProgressArea, circularProgress } = loadingStyles();
    const { componentArea, heading1 } = commonStyles();


    return (
        <div className={componentArea}>
            <h1 className={heading1}>loading...</h1>
            <div className={circularProgressArea}>
                <CircularProgress className={circularProgress} />
            </div>
        </div>
    )
});

export default Loading;
