import { useEffect } from 'react';
import { commonStyles } from '../assets/style/common';
import { notFoundStyles } from '../assets/style/notFound';
import { titleName, titleSeparator } from '../assets/const';

const NotFound = () => {
    useEffect(() => { document.title = `Not Found ${titleSeparator}${titleName}` }, []);

    useEffect(() => {
        // 10秒後にトップにリダイレクト
        setTimeout(() => { window.location.href = '/' }, 10000);
    }, []);

    const { paragraphArea } = notFoundStyles();
    const { componentArea, heading1 } = commonStyles();
    return (
        <div className={componentArea}>
            <h1 className={heading1}>Not Found</h1>
            <div className={paragraphArea}>
                <p>お探しのページは存在しません。<br />10秒後にトップに移動します。</p>
            </div>
        </div>
    );
}

export default NotFound;
