import firebase from '../firebase/firebaseInit'
import 'firebase/storage'

export const getImageMap = async (dirName: string, imgNames: string[]) => {
    const storage = firebase.storage()
    const imgNamePathMap = new Map();
    for (let imgName of imgNames) {
        const ref = storage.ref(dirName + imgName)
        const url = await ref.getDownloadURL()
        imgNamePathMap.set(imgName, url)
    }

    return imgNamePathMap
}
