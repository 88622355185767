import {
    makeStyles,
} from "@material-ui/core";

export const headerStyles = makeStyles(() => ({
    header: {
        backgroundColor: "#ffffff",
        paddingRight: "79px",
        paddingLeft: "118px",
        "@media (max-width: 900px)": {
            paddingLeft: 0,
        },
        color: "#000000",
        // position: "initial",
        position: 'fixed',
    },
    logo: {
        fontFamily: "Work Sans, sans-serif",
        fontWeight: 900,
        color: "#000000",
        textAlign: "left",
    },
    menuButton: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        size: "18px",
        marginLeft: "38px",
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
    drawerContainer: {
        padding: "20px 30px",
    },
}));