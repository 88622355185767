import {
    makeStyles,
} from "@material-ui/core";

export const footerStyles = makeStyles(() => ({
    footer: {
        height: "150px",
        width: '100%',
        background: "-webkit-linear-gradient(0deg, #ACB6E5, #86FDE8)",
        display: 'flex',
        position: 'absolute',
        boxSizing: 'border-box',
        bottom: '0',
        // paddingTop: "20px",
        // paddingRight: "20px",
        // paddingLeft: "20px",
        // background: "linear-gradient(to bottom right, blue, pink)",
    },
    footerCopyright: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        color: "#ffffff",
    },
    upBtn: {
        display: "block !important",
        margin: "0 0 0 auto !important",
        backgroundColor: "#ffffff !important",
        width: "45px !important",
        height: "45px !important",
    },
}));